import React from 'react';
import { AppBar, Toolbar, Typography, Button, Stack, Container, Grid } from '@mui/material';
import { getAuth, signOut } from 'firebase/auth';
import { Link as RouterLink } from 'react-router-dom';
import PublicNavbar from './PublicNavbar';

const Navbar = () => {
	const auth = getAuth();
	return (
		<>
			{!Boolean(auth.currentUser) && <PublicNavbar />}
			<AppBar position="static" sx={{ mb: 5 }}>
				<Toolbar>
					<Container>
						<Grid container spacing={3} justifyContent="space-between">
							<Grid item>
								<Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
									موجز
								</Typography>
							</Grid>
							{Boolean(auth.currentUser) && (
								<Grid item>
									<Stack
										direction="row"
										justifyContent="space-between"
										spacing={1}
									>
										<Button component={RouterLink} to="/create" color="inherit">
											إنشاء بريد الإلكتروني
										</Button>
										<Button
											component={RouterLink}
											to="/manage-users"
											color="inherit"
										>
											ادارة المستخدمين
										</Button>
										<Button
											color="inherit"
											onClick={() => {
												signOut(auth);
											}}
										>
											تسجيل خروج
										</Button>
									</Stack>
								</Grid>
							)}
						</Grid>
					</Container>
				</Toolbar>
			</AppBar>
		</>
	);
};

export default Navbar;
