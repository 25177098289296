import React, { useEffect, useState } from 'react';
import {
	Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from "tss-react/mui";
import { getFirestore, getDocs, query, where, collection, deleteDoc, doc, getDoc } from 'firebase/firestore';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import { useHistory } from 'react-router-dom';
import {ReactComponent as Logo} from "../../img/svgLogo.svg";
import {ReactComponent as SmallLogo} from "../../img/logo.svg";
import { LoadingButton } from '@mui/lab';
import EmailIcon from '@mui/icons-material/Email';


const Unsubscribe = () => {
	const [isLoading, setIsLoading] = useState(false);
  const [unsubscribed, setUnsubscribed] = useState(false);
  const history = useHistory();
  const [email, setEmail] = useState('');
	const [status, setStatus] = useState({ status: 'pending', message: '' });

	const db = getFirestore();

  const scrollPage = (id,mb) => {
		if(id){
			history.push(`/${id}/${mb ? mb : ''}`);
	}
}

	const useStyles = makeStyles()(
    () => ({
      container:{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        // justifyContent: 'center',
        height: '100vh',
        color: '#FD7422'
      },
      navContainer:{
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
      },
      contentWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 'calc(100vh - 240px)',
        flexGrow: 1,
        marginBottom: -40
      },
      topLogo:{
        width: '50%',
        cursor: 'pointer'
      },
      content:{
        width: '60%',
        // marginBottom: '-40px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '0px 20px',
        '@media only screen and (max-width: 950px)':{
          width: '100%'
        },
        '& h5':{
          textAlign: 'center',
          fontWeight: 'bold'
        }
      },
      textContent:{
        marginTop: 20,
        marginBottom: 20,
        width: '50%',
        '@media only screen and (max-width: 650px)':{
          width: '80%'
        },
      },
      email:{
        marginTop: 15
      },
      helperText: {
        fontSize: '0.75em',
        color: '#FD7422',
        marginTop: 8
      },
      buttonContainer:{
        display: 'flex',
        justifyContent: 'space-around',
        '& button':{
          borderRadius: 50,
          width: 125,
        },
        marginBottom: 20,
        '@media only screen and (max-width: 300px)':{
          '& button':{
            width: 90,
          },
        },
      },
      cancelButton: {
        background: '#FCFCFC',
        color: '#FD7422',
        marginRight: 60
      },
      logo: {
        display: 'flex',
        justifyContent: 'center',
        '& svg': {
          width: 50
        }
      },
      contactInfo: {
        marginTop: 30,
        '& span':{
          display: 'block',
          textAlign: 'center'
        }
      },
      footer: {
        flexShrink: 0
      },
      typography: {
        '@media only screen and (max-width: 450px)':{
          fontSize: '1rem'
        },
      }
    })
);

const { classes } = useStyles();

const handleUnsubscribe = async () => {
  setIsLoading(true);
  let q = query(collection(db, 'users'), where('email', '==', email));
	let searchForEmail = await getDocs(q);
	if (searchForEmail.size != 0) {
    let docId = searchForEmail.docs[0].id;
    const docRef = doc(db, "users", docId);
    const res =  await deleteDoc(docRef);
    setUnsubscribed(true);
    setIsLoading(false);
  }else{
    setStatus({
			status: 'error',
			message: 'عذرًا، البريد الإلكتروني الذي أدخلته غير مسجل'
		});
    setIsLoading(false);
  }
}

	return (
    <div className={classes.container}>
      <div className={classes.contentWrapper}>
        <div className={classes.content}>
          <Logo className={classes.topLogo} onClick={() => history.push('/')} />
          {!unsubscribed ? (
          <>
          <div className={classes.textContent}>
            <Typography className={classes.typography} variant='h5' >نأسف سماع أنه  سوف يتم إلغاء اشتراكك  لن تصلك نشرات موجز بعد الآن</Typography>
            {/* <Typography variant='h5' className={classes.email}>{}</Typography> */}
            <TextField
              className={classes.email}
              fullWidth
							onChange={({ target: { value } }) => setEmail(value)}
							value={email}
              autoComplete="email"
              label="البريد الالكتروني"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon />
                  </InputAdornment>
                )
              }}
            />
            <Typography className={classes.helperText}>
												{status.message}
						</Typography>

          </div>
          <div className={classes.buttonContainer}> 
            <LoadingButton loading={isLoading} variant="contained" onClick={handleUnsubscribe} >
               تأكيد 
            </LoadingButton>
            <LoadingButton variant="contained"  className={classes.cancelButton} onClick={() => history.push('/')}>
               تراجع       
            </LoadingButton>
          </div>
          </>
          ) : (
            <>
            <Typography className={classes.typography} variant='h5'>تم إلغاء اشتراكك بنجاح.</Typography>
            <div className={classes.contactInfo}>
              <Typography variant='h7'>لمزيد من المعلومات</Typography>
              <Typography variant='h7'>hello@mujaz.io</Typography>
            </div>
            </>
          )}
          <div className={classes.logo}>
            <SmallLogo />
          </div>
        </div>
      </div>
      <div className={classes.footer}>
      < Footer  />
      </div>
    </div>
	);
};

export default Unsubscribe;
