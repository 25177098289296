import React, { useState } from 'react';
import {
	TextField,
	Grid,
	// InputAdornment,
	Typography,
	Button,
	// Box
} from '@mui/material';
import { makeStyles } from "tss-react/mui";
// import { LoadingButton } from '@mui/lab';
import { getFirestore, addDoc, getDocs, query, where, collection, serverTimestamp } from 'firebase/firestore';
import banner from "../img/homepage_banner.png";
import MujazLogo from "../img/Mujaz_Logo.png";
//import MobileBanner from '../img/homepage_banner_mobile.jpg';
import MobileBanner from '../img/mujaz-pic-mobile.v.png';
import Intro from '../components/Intro';


const LandingBanner = ({ id }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [email, setEmail] = useState('');
	const [status, setStatus] = useState({ status: 'pending', message: '' });

	const db = getFirestore();

	const useStyles = makeStyles()(
		() => ({
			bannerWrap: {
				overflow: 'hidden',
				position: 'relative',
				display: 'flex',
				height: 'auto',
				paddingBottom: 100,
				justifyContent: 'center',
				'@media only screen and (min-width: 921px)': {
					height: 'auto',

				},
				'@media only screen and (max-width: 1200px)': {
					height: 'auto'
				},
				'@media only screen and (max-width: 500px)': {
					height: 'auto',
					minHeight: 'unset',

				}
			},
			bannerImg: {
				position: 'absolute',
				left: -450,
				top: 0,
				width: '69%',
				height: 'auto',
				filter: 'brightness(100%)',
				'@media only screen and (max-width: 900px)': {
					height: '100%',
					display: 'none',
				}
			},
			formGrid: {
				marginTop: 60,
				width: '100%',
				'@media only screen and (max-width: 900px)': {
					width: '100%',
					display: 'block',

				},
			},
			bannerContent: {
				position: 'relative',
				display: 'flex',
				justifyContent: "flex-start",
				alignItems: 'right',
				maxWidth: 1200,
				'&>div': {
					width: '60%'
				},
				'@media only screen and (max-width: 900px)': {
					width: '100%',
					justifyContent: "center",

				},
				'@media only screen and (min-width: 1200px)': {
					fontSize: 20
				}
			},
			formContainer: {
				'@media only screen and (max-width: 900px)': {
					width: '90% !important',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					fontSize: 15,
					'& form': {
						width: '70%'
					}
				},
				'@media only screen and (max-width: 600px)': {
					'& form': {
						width: '100%'
					},

				},
			},
			heading: {
				fontSize: '4em',
				// color: "#ff6600",
				color: "#112f52",
				whiteSpace: 'nowrap',
				fontWeight: 600,
				'@media only screen and (max-width: 600px)': {
					fontSize: '2.5em',
				},

			},
			subHeading: {
				color: "#000",
				fontSize: '1em',
				'@media only screen and (max-width: 900px)': {
					textAlign: 'center',
					width: '50%'
				},
				'@media only screen and (max-width: 600px)': {
					width: '70%',
					fontSize: '0.8em'
				},
				'@media only screen and (max-width: 400px)': {
					width: '90%',
				}
			},
			mobilePhone: {
				display: 'none',
				'@media only screen and (max-width: 900px)': {
					width: '100%',
					display: 'block',



				},
			},
			mobileImageDiv: {
				display: 'block',
				position: 'relative',
				height: 'auto'
			},
			banerInput: {
				borderRadius: 5,
				border: '1px solid #666',
				boxShadow: '0px 1px 3px #ccc',
				background: '#fff',
				paddingLeft: 0,
				marginLeft: 10,
				width: 500,
				'&>div': {
					paddingLeft: 6,
					'&>input': {
						padding: 12
					}
				},
				"& fieldset": {
					border: "none",
					outline: 'none'
				},
				'@media only screen and (max-width: 900px)': {
					width: '100%',
				},
			},
			inputButton: {
				borderRadius: 5,
				width: 125,
				'@media only screen and (max-width: 900px)': {
					width: '90%',
					display: 'block',
					marginRight: 'auto',
					marginLeft: 'auto',
					marginTop: 20
				},

			},
			logoWhite: {
				width: '100%',
				marginTop: 25,
				'@media only screen and (max-width: 900px)': {
					display: 'none'
				},
			},
			helperText: {
				fontSize: '0.75em',
				color: '#000',
				marginTop: 8
			},
			logo: {
				width: '85%',
				'@media only screen and (max-width: 900px)': {
					display: 'none',
				}
			},
			mob_logo: {
				width: '85%',
				'@media only screen and (min-width: 900px)': {
					display: 'none',
				}
			}

		})
	);

	const handleSubmit = async () => {
		setIsLoading(true);

		// let q = query(collection(db, 'users'), where('email', '==', email));
		let q = query(collection(db, 'Subscribers'), where('email', '==', email), where('company', '==', 'mujaz'));
		let searchForEmail = await getDocs(q);
		if (searchForEmail.size === 0) {
			// await addDoc(collection(db, 'users'), { email: email, createdAt: serverTimestamp() });
			await addDoc(collection(db, 'Subscribers'), { email: email, company: 'mujaz', name: '', createdAt: serverTimestamp() });
			setStatus({
				status: 'success',
				message: 'تم تسجيل بريدك الاكتروني بنجاح! اهلًا وسهلًا بك الى عائلة موجز.',
			});
			setEmail("");
			document.location.hash = '/thankyou';
		} else {
			setStatus({
				status: 'error',
				message:
					'بريدك الإلكتروني مسجل بالفعل في نظامنا. إذا كنت لا تتلقى رسائل البريد الإلكتروني الخاصة بنا ، فيرجى التحقق من مجلدات البريد العشوائي أو غير المرغوب فيه.',
			});
		}
		setIsLoading(false);
	};

	const onChange = (email) => {
		setEmail(email);
		if (status.message != '') {
			setStatus({
				status: 'pending',
				message: ''
			})
		}
	}

	const { classes } = useStyles();

	return (
		<Grid
			container
			id={id}
		>
			<Grid item xs={12}
				className={classes.banner}>
				<div className={classes.bannerWrap}>

					<div className={classes.bannerContent}>
						<div className={classes.formContainer}>
							<div className={classes.heading}>
								حكاية من الأخبار
							</div>
							<div className={classes.subHeading}>
								احصل على نشرة صباحية يومية لإبقائك على اطلاع دائم بأهم الأخبار والأحداث العالمية فقط في 5 دقائق من وقتك. مجاناً!!
							</div>
							<img className={classes.mob_logo} src={MujazLogo} alt="logo" />
							<form>
								<Grid container className={classes.formGrid}>
									<Grid item>
										<TextField
											className={classes.banerInput}
											disabled={isLoading}
											onChange={({ target: { value } }) => onChange(value)}
											value={email}
											autoComplete="email"
											placeholder="البريد الإلكتروني"
										/>
									</Grid>
									<Grid item alignItems="stretch" style={{ display: "flex" }}>
										<Button className={classes.inputButton}
											variant="contained"

											onClick={handleSubmit}
										>جربها الآن</Button>
									</Grid>
								</Grid>
								<Typography className={classes.helperText}>
									{status.message}
								</Typography>
							</form>
							<img
								className={classes.bannerImg}
								src={banner}
								alt=""
							/>
							<div className={classes.mobileImageDiv}>
								<img
									className={classes.mobilePhone}
									src={MobileBanner}
									alt=""
								/>
							</div>
							<img className={classes.logo} src={MujazLogo} alt="logo" />
							<Intro id="intro" />

						</div>

					</div>
				</div>
			</Grid>
		</Grid >
	);
};

export default LandingBanner;
