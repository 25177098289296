import { Divider, Grid, Stack, TextField } from '@mui/material';
import React, { useState, useEffect } from 'react';
import RichTextEditor from '../atoms/RichTextEditor';
import ImageUploader from './ImageUploader';


const NewsCardWithImage = ({ onChange, defaultValue }) => {
	const [section, setSection] = useState(
		defaultValue && defaultValue.section ? defaultValue.section : ''
	);
	const [title, setTitle] = useState(
		defaultValue && defaultValue.title ? defaultValue.title : ''
	);
	const [body, setBody] = useState(defaultValue && defaultValue.body ? defaultValue.body : '');
	const [image, setImage] = useState(
		defaultValue && defaultValue.image ? defaultValue.image : ''
	);
	const [credits, setCredits] = useState(
		defaultValue && defaultValue.credits ? defaultValue.credits : ''
	);
	const [alt, setAlt] = useState(defaultValue && defaultValue.alt ? defaultValue.alt : '');
	

	useEffect(() => {
		onChange({
			section,
			title,
			body,
			image,
			alt,
			credits,
		});
	}, [section, title, image, alt, credits, body]);

	return (
		<Stack divider={<Divider />} spacing={3} direction="column">
			<Stack direction="column" spacing={1}>
				<TextField
					fullWidth
					value={section}
					onChange={({ target: { value } }) => setSection(value)}
					variant="outlined"
					label="الفقرة"
					placeholder="الفقرة"
				/>
				<TextField
					fullWidth
					value={title}
					onChange={({ target: { value } }) => setTitle(value)}
					variant="outlined"
					label="العنوان"
					placeholder="العنوان"
				/>
			</Stack>
			<ImageUploader image={image} onChange={setImage} />
			<Stack direction="row" spacing={1}>
				<TextField
					fullWidth
					value={credits}
					onChange={({ target: { value } }) => setCredits(value)}
					variant="outlined"
					label="المصدر"
					placeholder="المصدر"
				/>
				<TextField
					fullWidth
					value={alt}
					onChange={({ target: { value } }) => setAlt(value)}
					variant="outlined"
					label="وصف الصورة"
					placeholder="وصف الصورة"
				/>
			</Stack>
			<RichTextEditor defaultValue={body} onChange={setBody} />
		</Stack>
	);
};

export default NewsCardWithImage;
