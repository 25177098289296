import React from 'react';
import { Grid, Typography, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const ErrorPage = () => {
	return (
		<Grid
			container
			spacing={3}
			alignContent="center"
			alignItems="center"
			justifyContent="center"
			style={{ minHeight: '100vh' }}
			direction="column"
		>
			<Grid item>
				<Typography variant="h1">😞</Typography>
			</Grid>
			<Grid item>
				<Typography variant="h2">404</Typography>
			</Grid>
			<Grid item>
				<Link component={RouterLink} to="/">
					Go back to home
				</Link>
			</Grid>
		</Grid>
	);
};

export default ErrorPage;
