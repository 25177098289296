import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSigninCheck } from 'reactfire';
import DashboardContainer from '../containers/DashboardContainer';

const PrivateRoute = ({ children, ...rest }) => {
	const { data: signInCheckResult } = useSigninCheck();

	return (
		<Route
			{...rest}
			render={(props) =>
				signInCheckResult.signedIn ? (
					<DashboardContainer>{children}</DashboardContainer>
				) : (
					<Redirect
						to={{
							pathname: '/login',
							state: { from: props.location },
						}}
					/>
				)
			}
		/>
	);
};

export default PrivateRoute;
