import { Button, Container, Grid, IconButton, Stack } from '@mui/material';
import React from 'react';
import EmailIcon from '@mui/icons-material/Email';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';

const PublicNavbar = () => {
	return (
		<nav>
			<Container>
				<Grid py={3} container justifyContent="space-between" alignItems="center">
					<Grid item>
						<Stack spacing={3} direction="row">
							<img
								src="https://firebasestorage.googleapis.com/v0/b/mujaz-42d66.appspot.com/o/Moujaz-emblum.png?alt=media&token=73295b87-dc0c-4b9f-950f-4c24bab5c4ba"
								alt="logo"
								style={{ height: 50 }}
							/>
							{console.log('ziad')}
							<Stack spacing={1} direction="row">
								<Button>الرئيسية</Button>
								<Button>من نحن</Button>
								<Button>النشرات السابقة</Button>
								<Button>التواصل معنا</Button>
							</Stack>
						</Stack>
					</Grid>
					<Grid item>
						<Stack spacing={1} direction="row">
							<IconButton>
								<FacebookOutlinedIcon />
							</IconButton>
							<IconButton>
								<TwitterIcon />
							</IconButton>
							<IconButton>
								<InstagramIcon />
							</IconButton>
						</Stack>
					</Grid>
				</Grid>
			</Container>
		</nav>
	);
};

export default PublicNavbar;
