import {
	addDoc,
	collection,
	deleteDoc,
	doc,
	getDocs,
	getFirestore,
	orderBy,
	query,
	startAfter,
	limit,
	serverTimestamp,
	where
} from '@firebase/firestore';
import Delete from '@mui/icons-material/Delete';
import LoadingButton from '@mui/lab/LoadingButton';
import {
	Button,
	Container,
	Grid,
	IconButton,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from "tss-react/mui";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


const ManageUsers = () => {
	const db = getFirestore();

	const [isLoading, setIsLoading] = useState(false);
	const [lastDocument, setLastDocument] = useState();
	const [users, setUsers] = useState([]);
	const [user, setUser] = useState({
		name: '',
		email: '',
	});
	const [currentPage, setCurrentPage] = useState(1);
	const [currentLimit] = useState(10);
	const [forwarddDisabled, setForwarddDisabled] = useState(false);
	const [fetchFinish, setFetchFinish] = useState({maxPage:0, isFinish: false});
	const [monthAgo, setMonthAgo] = useState();
	const [weekAgo, setWeekAgo] = useState();
	const [today, setToday] = useState();

	
	const useStyles = makeStyles({currentPage,forwarddDisabled})(
    () => ({
      pagination: {
				display: 'flex',
				justifyContent: 'center',
				marginTop: 20,
				padding: 20,
				'& svg':{
					marginRight: 20,
					cursor: 'pointer',
				},
				'& svg:first-child':{
					marginRight: 0
				}
			},
			backward:{
				fill : currentPage === 1 ? '	#A9A9A9' : '#000'
			},
			forward: {
				fill : forwarddDisabled ? '	#A9A9A9' : '#000'
			}
    })
);

const { classes } = useStyles({currentPage, forwarddDisabled});

	const onAdd = async () => {
		setIsLoading(true);
		let request = await addDoc(collection(db, 'users'), { ...user, createdAt: new Date() });
		setUsers([...users, { key: request.id, ...user }]);
		setUser({
			name: '',
			email: '',
		});
		setIsLoading(false);
	};

	const fetchMonthAgo = async() => {
		const current = new Date();
		var pastDate = current.getDate() - 90;
		current.setDate(pastDate);
		let q = query(collection(db, 'users'), where("createdAt", ">", current));
		const querySnapshot = await getDocs(q);
		setMonthAgo(querySnapshot.size);
		filterWeekAndToday(querySnapshot);
	}

	const filterWeekAndToday = (querySnapshot) => {
		let week = 0,today = 0;
		const todayData = new Date();
		querySnapshot.forEach((entities) => {
			console.log(entities.data().createdAt.toDate())
			const diffTime = Math.abs(entities.data().createdAt.toDate() - todayData);
			const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
			if(diffDays < 7){
				week++;
				if(diffDays <= 1){
					today++;
				}
			}
		});
		setWeekAgo(week);
		setToday(today);
	}

	const init = useCallback(async () => {
		let tempUsers = [];
		let q = query(collection(db, 'users'), orderBy('createdAt', 'desc'), limit(10));
		const querySnapshot = await getDocs(q);

		const lastVisible = querySnapshot.docs[querySnapshot.docs.length-1];
		setLastDocument(lastVisible);
		querySnapshot.forEach((entities) => {
			// doc.data() is never undefined for query doc snapshots
			tempUsers.push({ key: entities.id, ...entities.data() });
		});
		setUsers(tempUsers);
	}, [db]);

	useEffect(() => {
		init();
		fetchMonthAgo()
	}, [init]);

	const onDelete = async (key) => {
		console.log(key);
		setUsers(users.filter((u) => u.key !== key));
		await deleteDoc(doc(db, 'users', key));
	};

	const handlePaginate = async (index) => {
		let tempUsers = [];
		let querySnapshot;
		let page = currentPage;
		let nextQuery;
		if(index){
		
			if(!fetchFinish.isFinish){
				nextQuery = query(collection(db, 'users'), orderBy('createdAt', 'desc'), startAfter(lastDocument), limit(10));
				querySnapshot = await getDocs(nextQuery);
					++page;
		
				 if(querySnapshot.docs.length){
					 const lastVisible = querySnapshot.docs[querySnapshot.docs.length-1];
					 setLastDocument(lastVisible);
			 
					 querySnapshot.forEach((entities) => {
						 tempUsers.push({ key: entities.id, ...entities.data() });
					 });
					 setCurrentPage(page);
					 setUsers([...users, ...tempUsers]);
					 forwarddDisabled && setForwarddDisabled(false);
				 }else{
					 setFetchFinish({ maxPage:page,isFinish:true });
					 setForwarddDisabled(true);
				 }
			}else{
				page !== fetchFinish.maxPage && ++page;
				setCurrentPage(page);
				page === fetchFinish.maxPage - 1 && !forwarddDisabled && setForwarddDisabled(true);
			}

		}else {
			--page;
			setCurrentPage(page);
			forwarddDisabled && setForwarddDisabled(false);
		}
	
	}

	return (
		<Container>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Typography variant="h3">ادارة المستخدمين</Typography>
				</Grid>
				<Grid item xs={12}>
					<Stack direction="row" spacing={3} flexGrow={1}>
						<TextField
							label="الاسم"
							value={user.name}
							onChange={({ target: { value } }) => setUser({ ...user, name: value })}
						/>
						<TextField
							label="البريد الإلكتروني"
							value={user.email}
							onChange={({ target: { value } }) => setUser({ ...user, email: value })}
						/>
						<LoadingButton loading={isLoading} variant="outlined" onClick={onAdd}>
							إضافة عنوان البريد الإلكتروني
						</LoadingButton>
					</Stack>
				</Grid>
				<Grid item xs={12}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Name</TableCell>
								<TableCell>Email</TableCell>
								<TableCell></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{users.map((person,index) => {
								if(index < currentPage*currentLimit && (currentPage === 1 ? true : index >= currentPage*currentLimit -10 )){
									return(
										<TableRow key={person.key}>
											<TableCell>{person.name}</TableCell>
											<TableCell>{person.email}</TableCell>
											<TableCell>
												<IconButton onClick={() => onDelete(person.key)}>
													<Delete />
												</IconButton>
											</TableCell>
										</TableRow>
									)
								}
							}
							)}
						</TableBody>
					</Table>
				</Grid>
			</Grid>
			<div className={classes.pagination}>
				<IconButton disabled={currentPage === 1} onClick={() => handlePaginate(0)}>
					<ArrowForwardIosIcon className={classes.backward}  />
				</IconButton>
				<IconButton disabled={forwarddDisabled} onClick={() => handlePaginate(1)}>
					<ArrowBackIosIcon className={classes.forward}  />
				</IconButton>
    </div>
		</Container>
	);
};

export default ManageUsers;
