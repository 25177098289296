import React, { useEffect, useState } from 'react';
import {
	Button,
	CircularProgress,
	Container,
	Divider,
	Grid,
	Stack,
	TextField,
	Typography,
	Snackbar,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { generateIntroCard, generateKey, generateWrappedEmail, swapArrayItems } from '../../utils';
import { doc, addDoc, getDoc, collection, getFirestore, getDocs, setDoc } from 'firebase/firestore';
import SplitButton from '../../atoms/SplitButton';
import IntroCard from '../../components/IntroCard';
import HTMLViewer from '../../components/HTMLViewer';
import NewsCardContainer from '../../components/NewsCardContainer';

const TYPES = [
	{
		key: 'newsCardWithImage',
		label: '+ خبر',
	},
	{
		key: 'newsImageCard',
		label: '+ صورة',
	},
	{
		key: 'newsStocksCard',
		label: '+ بورصة',
	},
	{
		key: 'newsWeatherCard',
		label: '+ الطقس',
	},
	{
		key: 'newsCurrencyCard',
		label: '+ العملات',
	},
];

const EMPTY_TEMPLATES = {
	newsCardWithImage: {
		section: '',
		title: '',
		image: '',
		body: '',
		key: 'newsCardWithImage',
	},
	newsImageCard: { section: '', title: '', image: '', key: 'newsImageCard' },
	newsStocksCard: { stocks: [], body: '', key: 'newsStocksCard' },
	newsWeatherCard: { weather: [], key: 'newsWeatherCard' },
	newsCurrencyCard: { currencies: [], key: 'newsCurrencyCard' },
};

const CreateEmail = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [subject, setSubject] = useState('');
	const [intro, setIntro] = useState('');
	const [items, setItems] = useState([]);
	const [snackBar, setSnackBar] = useState({
		isOpen: false,
		message: '',
	});
	const db = getFirestore();

	const sendTestEmail = async (toUids) => {
		let html = generateIntroCard(intro);
		html = html + items.reduce((prev, curr) => prev + curr.html, '');
		await addDoc(collection(db, 'mail'), {
			toUids: [toUids],
			message: {
				subject: subject,
				html: generateWrappedEmail(html, subject, intro.substr(0, 100) + '...'),
			},
		});
	};

	const getDateId = () => {
		return `${parseInt(new Date().getDate()) + (new Date().getHours() < 5 ? 0 : 1)}-${
			new Date().getMonth() + 1
		}-${new Date().getFullYear()}`;
	};

	const scheduleEmail = async () => {
		setIsLoading(true);
		let html = generateIntroCard(intro);
		html = html + items.reduce((prev, curr) => prev + curr.html, '');
		html = generateWrappedEmail(html, subject, intro.substr(0, 100) + '...');

		let emails = [];

		const querySnapshot = await getDocs(collection(db, 'users'));
		querySnapshot.forEach((item) => {
			emails.push(item.id);
		});

		for (const uid of emails) {
			await addDoc(collection(db, 'mail'), {
				toUids: [uid],
				message: {
					subject,
					html,
				},
			});
		}
		setIsLoading(false);
		setSnackBar({
			isOpen: true,
			message: 'أرسلت رسائل البريد الإلكتروني بنجاح',
		});
	};

	const onChangeItem = (newItem, index) => {
		let temp = [...items];
		temp.splice(index, 1, newItem);
		setItems(temp);
	};

	const onDelete = (index) => {
		const temp = [...items];
		temp.splice(index, 1);
		setItems(temp);
	};

	const onMove = (direction, index) => {
		setItems(
			swapArrayItems(items, index, direction === 'up' ? index - 1 : index + 1).map(
				(a, i) => ({ ...a, order: i })
			)
		);
	};

	const getDetails = async () => {
		let docTitle = getDateId();
		let data = await getDoc(doc(db, 'newsletters', docTitle));
		if (data.data()) {
			setItems(data.data().items);
			setIntro(data.data().text);
			setSubject(data.data().subject);
		}
		setIsLoading(false);
	};

	useEffect(() => {
		getDetails();
	}, []);

	const onSave = async () => {
		let docTitle = getDateId();

		let html = generateIntroCard(intro);
		html = html + items.reduce((prev, curr) => prev + curr.html, '');

		await setDoc(doc(db, 'newsletters', docTitle), {
			items,
			subject,
			text: intro,
			html: generateWrappedEmail(html, subject, intro.substr(0, 100) + '...'),
		});

		setSnackBar({
			isOpen: true,
			message: 'تم الحفظ بنجاح',
		});
	};

	return (
		<Container>
			<Grid container spacing={3} style={{ marginBottom: 100, marginTop: 5 }}>
				{isLoading ? (
					<CircularProgress />
				) : (
					<>
						<Grid item xs={12}>
							<Grid
								container
								spacing={3}
								justifyContent="space-between"
								alignItems="center"
							>
								<Grid item>
									<Typography variant="h3">إنشاء بريد إلكتروني</Typography>
								</Grid>
								<Grid item>
									<Stack direction="row" spacing={1}>
										<SplitButton
											options={['ziad', 'somar', 'maha', 'hussein']}
											onClick={sendTestEmail}
										/>
										<Button variant="outlined" onClick={onSave}>
											حفظ
										</Button>
										<LoadingButton
											loading={isLoading}
											variant="contained"
											disableElevation
											color="primary"
											onClick={scheduleEmail}
										>
											جدولة البريد الإلكتروني
										</LoadingButton>
									</Stack>
								</Grid>
							</Grid>
						</Grid>
						<Grid item md={6} xs={12}>
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<TextField
										value={subject}
										onChange={({ target: { value } }) => setSubject(value)}
										fullWidth
										label="عنوان البريد"
									/>
								</Grid>
								<Grid item xs={12}>
									<IntroCard defaultValue={intro} onChange={setIntro} />
								</Grid>
								{items.map((item, index) => (
									<Grid item xs={12} key={item.id}>
										<NewsCardContainer
											onChange={onChangeItem}
											onDelete={onDelete}
											onMove={onMove}
											item={item}
											total={items.length}
											index={index}
										/>
									</Grid>
								))}
								<Grid item xs={12}>
									<Stack
										direction="row"
										divider={<Divider orientation="vertical" />}
										justifyContent="center"
										spacing={1}
									>
										{TYPES.map((type) => (
											<Button
												key={type.key}
												color="primary"
												style={{ height: '100%' }}
												onClick={() => {
													const newItem = {
														...EMPTY_TEMPLATES[type.key],
														order: items.length,
														id: generateKey(),
													};
													setItems([...items, newItem]);
												}}
												variant="outlined"
											>
												{type.label}
											</Button>
										))}
									</Stack>
								</Grid>
							</Grid>
						</Grid>
						<Grid item md={6}>
							<HTMLViewer intro={intro} items={items} />
						</Grid>
					</>
				)}
			</Grid>
			<Snackbar
				open={snackBar.isOpen}
				autoHideDuration={6000}
				onClose={() => setSnackBar({ ...snackBar, isOpen: false })}
				message={snackBar.message}
			/>
		</Container>
	);
};

export default CreateEmail;
