import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import React from 'react';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import Router from './router';
import { getAuth } from 'firebase/auth'; // Firebase v9+
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { useFirebaseApp, AppCheckProvider, AuthProvider } from 'reactfire';

const APP_CHECK_TOKEN = '6Ld8dt0cAAAAADpRbnKw4OAYrPVzxUfK1oKCIgYn'; // production
// const APP_CHECK_TOKEN = '20ECA0EB-ED62-4F49-B354-A70DF4DD0620'; // debug/dev mujaz news
// const APP_CHECK_TOKEN = 'f0c86411-22ad-4371-913a-7aeadcd476b3'; // debug/dev mujazplatform db proj
const App = () => {
	const app = useFirebaseApp(); // a parent component contains a `FirebaseAppProvider`
	const auth = getAuth(app);
	// window.FIREBASE_APPCHECK_DEBUG_TOKEN=true
	const appCheck = initializeAppCheck(app, {
		provider: new ReCaptchaV3Provider(APP_CHECK_TOKEN),
		isTokenAutoRefreshEnabled: true,
	});

	const theme = createTheme({
		palette: {
			primary: {
				// main: '#ff7400', //'#F79325',
				contrastText: '#ffffff',
				main: '#112f52', //'#F79325',
				// contrastText: '#ffffff',
			},
		},
		direction: 'rtl',
		typography: {
			fontFamily: ['Tajawal, sans-serif'].join(','),
		},
		shape: {
			borderRadius: 30,
		},
	});

	// Create rtl cache
	const cacheRtl = createCache({
		key: 'muirtl',
		stylisPlugins: [rtlPlugin],
	});

	return (
		<AppCheckProvider sdk={appCheck}>
			<AuthProvider sdk={auth}>
				<CacheProvider value={cacheRtl}>
					<ThemeProvider theme={theme}>
						<CssBaseline />
						<Router />
					</ThemeProvider>
				</CacheProvider>
			</AuthProvider>
		</AppCheckProvider>
	);
};

export default App;
