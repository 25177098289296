import React, { useEffect, useState } from 'react';
import {
  TextField,
  Grid,
  Button,
  InputAdornment,
  Typography,
} from '@mui/material';
import { makeStyles } from "tss-react/mui";
import { LoadingButton } from '@mui/lab';
import { getFirestore, documentId, addDoc, getDocs, getDoc, doc, query, where, collection, orderBy } from 'firebase/firestore';
import Post from '../atoms/Post';
import { ArrowForwardIosTwoTone } from '@mui/icons-material';
import { queryAllByDisplayValue } from '@testing-library/react';
import DefaultNewsletterImage from '../img/pic-03.jpg';

const PreviousPost = ({ id }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [posts, setPosts] = useState([]);

  const db = getFirestore();

  const useStyles = makeStyles()(
    () => ({
      container: {
        backgroundColor: '#FCFCFC',
        marginTop: 40,
        paddingTop: 45,
        paddingBottom: 32
      },
      heading: {
        fontSize: '2em',
        fontWeight: 'bold',
        color: '#112f52'
      },
      seeMore: {
        cursor: 'pointer',
        fontSize: 25,
        marginTop: 40,
        borderRadius: 5
      },
      postContainer: {
        maxWidth: 1200,
        '@media only screen and (max-width: 600px)': {
          display: 'flex',
          justifyContent: 'center'
        },
        '@media only screen and (min-width: 1200px)': {

        },
      }
    })
  );

  const { classes } = useStyles();
  const [numberOfPosts, setnumberOfPosts] = useState(5);
  const [maxPost, setMaxPost] = useState(2);
  const [increment] = useState(4);
  const [selectedNewsletter, setSelectedNewsletter] = useState([]);
  const [disableBtn, setDisableBtn] = useState(false);

  const handleSeeMore = () => {
    setnumberOfPosts(numberOfPosts + increment);
    setMaxPost(maxPost + 3);

  }
  function _formatDate(dateIn, symbol) {
    let yyyy = dateIn.getFullYear();
    let mm = dateIn.getMonth() + 1;
    if (symbol != '-') {
      mm = mm.toString().padStart(2, '0');
    }
    let dd = dateIn.getDate();
    return String(dd + symbol + mm + symbol + yyyy);
  }
  function sortArrayNewsletterByDate(id) {
    let date = id.split('-');
    let d = new Date(date[2], date[1], date[0]);
    return d;


  }
  const fetchNewsletter = async () => {
    let dates = [];
    setSelectedNewsletter([]);
    {
      [...Array(numberOfPosts)].map((item, i) => {
        let date = new Date();
        date.setDate(date.getDate() - i);
        date = _formatDate(date, '-');
        dates.push("mujaz_" + date);
      })
    }
    console.log(dates);
    if (dates.length > 10) {
      dates.splice(10, dates.length - 10);
      setDisableBtn(true);
    } else {
      setDisableBtn(false);
    }
    // const q = query(collection(db, "newsletters"), where(documentId(), "in", dates), orderBy(documentId()));
    const q = query(collection(db, "Newsletters"), where(documentId(), "in", dates), orderBy(documentId()));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.size >= 6) {
      setDisableBtn(true);
    }
    querySnapshot.forEach((doc) => {

      if (doc.data()) {
        // Assuming you have the array stored in 'doc.data()'
        const dataArray = doc.data().items;

        // Filter the objects in the array that have a category available in the provided list
        const filteredArray = dataArray.filter(item => ['أخبار السياسة والعالم', 'اقتصاد', 'منوعات', 'ترفيه'].includes(item.category));

        // Select a random object from the filtered array
        const randomObject = filteredArray[Math.floor(Math.random() * filteredArray.length)];

        // Access the title and image properties of the randomly selected object
        const randomTitle = randomObject.title;
        const randomImage = randomObject.image;
        setSelectedNewsletter(selectedNewsletter => [...selectedNewsletter, {
          smallDate: doc.id,
          date: sortArrayNewsletterByDate(doc.id),
          title: randomTitle,
          image: randomImage
        }])

      }
    });

  }
  const filterImage = (obj) => {
    let obj_length = obj.length;
    let alts = ['Moujaz', 'موجز', 'sponsor ', 'market-arrow-up', 'market-arrow-down'];
    let img_array = [];
    {
      [...Array(obj_length)].map((item, i) => {
        if (!alts.includes(obj[i].alt)) {
          img_array.push(obj[i]);
        }
      });
      img_array.splice(img_array.length - 3, 3);
      return img_array;
    }
  }
  useEffect(() => {
    fetchNewsletter();
  }, [numberOfPosts]);

  return (
    <Grid
      container
      justifyContent={"center"}
      flexDirection={"column"}
      alignItems={"center"}
      className={classes.container}
      id={id}
    >
      <Typography className={classes.heading} variant="h1">نشرات السابقة</Typography>
      <Grid
        container
        marginTop={3}
        flexDirection={"row"}
        justifyContent={"space-evenly"}
        width={'100%'}
        className={classes.postContainer}
      >
        {selectedNewsletter.sort((a, b) => Number(b.date) - Number(a.date)).map((item, i) => {
          return (
            <Post key={i} date={selectedNewsletter[i].smallDate.replaceAll('-', '.')} image={selectedNewsletter[i].image} number={i} maxPost={maxPost} title={selectedNewsletter[i].title} />
          )
        })}
      </Grid>
      <Button disabled={disableBtn} variant='outlined' size='small' onClick={handleSeeMore} className={classes.seeMore}>المزيد</Button>
    </Grid>
  );
}

export default PreviousPost;
