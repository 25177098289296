import React, { useState } from 'react';
import {
	Grid,
	Button,
} from '@mui/material';
import { makeStyles } from "tss-react/mui";
import { getFirestore, addDoc, getDocs, query, where, collection } from 'firebase/firestore';
import logo from "../img/Mujaz_Logo_Mini.png";
import facebook from "../img/facebook.svg";
import instagram from "../img/instagram.svg";
import twitter from "../img/twitter.svg";
import SocialHolder from '../atoms/SocialHolder';
import MobileNav from './MobileNav';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const Nav = ({ scrollPage }) => {

	const db = getFirestore();

	const useStyles = makeStyles()(
		() => ({
			nav: {
				'@media only screen and (max-width: 990px)': {
					display: 'none'
				},
				'@media only screen and (min-width: 1200px)': {
					maxWidth: '100%',
				},
			},
			navButton: {
				color: '#000000',
				fontSize: 18
			},
			navButtonHolder: {
				display: 'flex',
				maxWidth: '1200px',
				margin: '0 auto',
			},
			logo: {
				width: 45,
				cursor: 'pointer'
			},
			socialHolder: {
				display: "flex",
				justifyContent: 'flex-end',
				'&>img': {
					marginRight: 33,
					width: 30,
					cursor: 'pointer',
					'&:first-of-type': {
						marginRight: 0
					}
				}
			},
			menu: {
				marginRight: '-5%',
			}
		})
	);

	const { classes } = useStyles();
	const history = useHistory();

	return (
		<>
			<>
				<Grid
					container
					padding={"16px 70px"}
					alignItems={"center"}
					height="fit-content"
					justifyContent={"space-between"}
					className={classes.nav}
				>
					<Grid item xs={1}>
						<img className={classes.logo} src={logo} alt="logo" onClick={() => history.push('/')} />
					</Grid>
					<Grid item xs={8} className={classes.navButtonHolder}>
						<Grid
							container
							spacing={5}
							className={classes.menu}
						>
							<Grid item >
								<Button className={classes.navButton}
									onClick={() => scrollPage("landing-banner")} variant="text">الرئيسية</Button>
							</Grid>
							<Grid item>
								<Button className={classes.navButton}
									onClick={() => scrollPage("intro")} variant='text'>	من نحن	</Button>
							</Grid>
							<Grid item>
								<Button className={classes.navButton}
									onClick={() => scrollPage("previous-post")} variant='text'>
									النشرات السابقة
								</Button>
							</Grid>
							<Grid item>
								<Button className={classes.navButton}
									onClick={() => scrollPage("contact-us")} variant="text">
									التواصل معنا
								</Button>
							</Grid>
						</Grid>
					</Grid>
					<SocialHolder />
					{/* <Grid item xs={2} className={classes.socialHolder}>
						<img src={facebook} alt="facebook" />
						<img src={twitter} alt="twitter" />
						<img src={instagram} alt="insta" />
				</Grid> */}
				</Grid>
			</>
			<MobileNav scrollPage={scrollPage} />
		</>

	);
};

export default Nav;
