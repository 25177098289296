import { Card, CardContent, Grid, Typography } from '@mui/material';
import React from 'react';
import RichTextEditor from '../atoms/RichTextEditor';

const IntroCard = ({ onChange, defaultValue }) => {
	return (
		<Card variant="outlined">
			<CardContent>
				<Grid container spacing={1} justifyContent="center">
					<Grid item xs={12}>
						<Grid container justifyContent="space-between">
							<Grid item>
								<Typography variant="caption">
									{Intl.DateTimeFormat('ar').format(new Date())}
								</Typography>
							</Grid>
							<Grid item>
								<Typography variant="caption">www.moujaz.com</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid item md={7}>
						<img
							src="https://firebasestorage.googleapis.com/v0/b/mujaz-42d66.appspot.com/o/Moujaz-01-01.png?alt=media&token=b6f0942c-5c4e-4f04-924f-a4885d6ae40d"
							style={{ width: '100%' }}
							alt="logo"
						/>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<RichTextEditor defaultValue={defaultValue} onChange={onChange} />
				</Grid>
			</CardContent>
		</Card>
	);
};

export default IntroCard;
