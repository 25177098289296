import { Button, Divider, Stack, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import RichTextEditor from '../atoms/RichTextEditor';
import { generateKey } from '../utils';
import StockRow from './StockRow';

const NewsStocksCard = ({ onChange, defaultValue }) => {
	const [stocks, setStocks] = useState(
		defaultValue
			? defaultValue.stocks
			: [
					{
						key: generateKey(),
						name: '',
						value: 0,
						change: '0',
					},
			  ]
	);
	const [body, setBody] = useState(defaultValue && defaultValue.body ? defaultValue.body : '');

	useEffect(() => {
		onChange({ stocks, body });
	}, [stocks, body]);

	return (
		<Stack spacing={3}>
			<Typography variant="h5">البورصة</Typography>
			<Stack direction="column" divider={<Divider />} spacing={2}>
				{stocks.map((stock, index) => (
					<StockRow
						key={stock.key}
						id={stock.key}
						onChange={(newStock) => {
							let temp = [...stocks];
							temp.splice(index, 1, newStock);
							setStocks(temp);
						}}
						item={stock}
						onDelete={() => {
							let temp = [...stocks];
							temp.splice(index, 1);
							setStocks(temp);
						}}
					/>
				))}
			</Stack>
			<Button
				fullWidth
				onClick={() =>
					setStocks([
						...stocks,
						{
							key: generateKey(),
							name: '',
							value: 0,
							change: '+0',
						},
					])
				}
			>
				اضافة
			</Button>
			<RichTextEditor defaultValue={body} onChange={setBody} />
		</Stack>
	);
};

export default NewsStocksCard;
