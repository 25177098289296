import React from 'react';
import { Grid, Typography } from '@mui/material';
import StaticPageContainer from '../../containers/StaticPageContainer';

const About = () => {
	return (
		<StaticPageContainer>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Typography variant="h3">من نحن؟</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography>
						مرحبا يا من هناك!نحن مجاز، شركة إعلامية ملتزمة بمساعدتك، زعيم الأعمال في
						الغد، أصبح أكثر ذكاء في دقائق.نحن في مهمة تقديم أخبار قصيرة وموضوعية لك كل
						صباح.
					</Typography>
					<Typography>
						نحن مقرنا في بيروت وإسطنبول، لكننا نغطي الأخبار في جميع أنحاء منطقة الشرق
						الأوسط وشمال إفريقيا والعالم.
					</Typography>
				</Grid>
			</Grid>
		</StaticPageContainer>
	);
};

export default About;
