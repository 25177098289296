import { convertToRaw, EditorState, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { makeStyles } from "tss-react/mui";

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const RichTextEditor = ({ onChange, defaultValue }) => {
	const [editorState, setEditorState] = useState('');

	const useStyles = makeStyles()(
    () => ({
				richTextWraper: {
					'& a': {
						color: "#000",
						textDecoration: 'none',
						borderBottom: '2px solid #F77C15',
						paddingBottom: '2px'
					}
				}
    })
);

const { classes } = useStyles();

	const toolbar = {
		options: ['emoji', 'link', 'inline', 'list', 'textAlign'],
		link: {
			popupClassName: 'link-popup',
		},
		emoji: {
			emojis: [
				'😀',
				'😁',
				'😂',
				'😃',
				'😉',
				'😋',
				'😎',
				'😍',
				'😗',
				'🤗',
				'🤔',
				'😣',
				'😫',
				'😴',
				'😌',
				'🤓',
				'😛',
				'😜',
				'😠',
				'😇',
				'😷',
				'😈',
				'👻',
				'😺',
				'😸',
				'😹',
				'😻',
				'😼',
				'😽',
				'🙀',
				'🙈',
				'🙉',
				'🙊',
				'👼',
				'👮',
				'🕵',
				'💂',
				'👳',
				'🎅',
				'👸',
				'👰',
				'👲',
				'🙍',
				'🙇',
				'🚶',
				'🏃',
				'💃',
				'⛷',
				'🏂',
				'🏌',
				'🏄',
				'🚣',
				'🏊',
				'⛹',
				'🏋',
				'🚴',
				'👫',
				'💪',
				'👈',
				'👉',
				'👉',
				'👆',
				'🖕',
				'👇',
				'🖖',
				'🤘',
				'🖐',
				'👌',
				'👍',
				'👎',
				'✊',
				'👊',
				'👏',
				'🙌',
				'🙏',
				'🐵',
				'🐶',
				'🐇',
				'🐥',
				'🐸',
				'🐌',
				'🐛',
				'🐜',
				'🐝',
				'🍉',
				'🍄',
				'🍔',
				'🍤',
				'🍨',
				'🍪',
				'🎂',
				'🍰',
				'🍾',
				'🍷',
				'🍸',
				'🍺',
				'🌍',
				'🚑',
				'⏰',
				'🌙',
				'🌝',
				'🌞',
				'⭐',
				'🌟',
				'🌠',
				'🌨',
				'🌩',
				'⛄',
				'🔥',
				'🎄',
				'🎈',
				'🎉',
				'🎊',
				'🎁',
				'🎗',
				'🏀',
				'🏈',
				'🎲',
				'🔇',
				'🔈',
				'📣',
				'🔔',
				'🎵',
				'🎷',
				'💰',
				'🖊',
				'📅',
				'✅',
				'❎',
				'💯',
				'🏁',
				'🚩',
				'🎌',
				'🏴',
				'🏳️',
				'🏳️‍🌈',
				'🏳️‍⚧️',
				'🏴‍☠️',
				'🇦🇨',
				'🇦🇩',
				'🇦🇪',
				'🇦🇫',
				'🇦🇬',
				'🇦🇮',
				'🇦🇱',
				'🇦🇲',
				'🇦🇴',
				'🇦🇶',
				'🇦🇷',
				'🇦🇸',
				'🇦🇹',
				'🇦🇺',
				'🇦🇼',
				'🇦🇽',
				'🇦🇿',
				'🇧🇦',
				'🇧🇧',
				'🇧🇩',
				'🇧🇪',
				'🇧🇫',
				'🇧🇬',
				'🇧🇭',
				'🇧🇮',
				'🇧🇯',
				'🇧🇱',
				'🇧🇲',
				'🇧🇳',
				'🇧🇴',
				'🇧🇶',
				'🇧🇷',
				'🇧🇸',
				'🇧🇹',
				'🇧🇻',
				'🇧🇼',
				'🇧🇾',
				'🇧🇿',
				'🇨🇦',
				'🇨🇨',
				'🇨🇩',
				'🇨🇫',
				'🇨🇬',
				'🇨🇭',
				'🇨🇮',
				'🇨🇰',
				'🇨🇱',
				'🇨🇲',
				'🇨🇳',
				'🇨🇴',
				'🇨🇵',
				'🇨🇷',
				'🇨🇺',
				'🇨🇻',
				'🇨🇼',
				'🇨🇽',
				'🇨🇾',
				'🇨🇿',
				'🇩🇪',
				'🇩🇬',
				'🇩🇯',
				'🇩🇰',
				'🇩🇲',
				'🇩🇴',
				'🇩🇿',
				'🇪🇦',
				'🇪🇨',
				'🇪🇪',
				'🇪🇬',
				'🇪🇭',
				'🇪🇷',
				'🇪🇸',
				'🇪🇹',
				'🇪🇺',
				'🇫🇮',
				'🇫🇯',
				'🇫🇰',
				'🇫🇲',
				'🇫🇴',
				'🇫🇷',
				'🇬🇦',
				'🇬🇧',
				'🇬🇩',
				'🇬🇪',
				'🇬🇫',
				'🇬🇬',
				'🇬🇭',
				'🇬🇮',
				'🇬🇱',
				'🇬🇲',
				'🇬🇳',
				'🇬🇵',
				'🇬🇶',
				'🇬🇷',
				'🇬🇸',
				'🇬🇹',
				'🇬🇺',
				'🇬🇼',
				'🇬🇾',
				'🇭🇰',
				'🇭🇲',
				'🇭🇳',
				'🇭🇷',
				'🇭🇹',
				'🇭🇺',
				'🇮🇨',
				'🇮🇩',
				'🇮🇪',
				'🇮🇱',
				'🇮🇲',
				'🇮🇳',
				'🇮🇴',
				'🇮🇶',
				'🇮🇷',
				'🇮🇸',
				'🇮🇹',
				'🇯🇪',
				'🇯🇲',
				'🇯🇴',
				'🇯🇵',
				'🇰🇪',
				'🇰🇬',
				'🇰🇭',
				'🇰🇮',
				'🇰🇲',
				'🇰🇳',
				'🇰🇵',
				'🇰🇷',
				'🇰🇼',
				'🇰🇾',
				'🇰🇿',
				'🇱🇦',
				'🇱🇧',
				'🇱🇨',
				'🇱🇮',
				'🇱🇰',
				'🇱🇷',
				'🇱🇸',
				'🇱🇹',
				'🇱🇺',
				'🇱🇻',
				'🇱🇾',
				'🇲🇦',
				'🇲🇨',
				'🇲🇩',
				'🇲🇪',
				'🇲🇫',
				'🇲🇬',
				'🇲🇭',
				'🇲🇰',
				'🇲🇱',
				'🇲🇲',
				'🇲🇳',
				'🇲🇴',
				'🇲🇵',
				'🇲🇶',
				'🇲🇷',
				'🇲🇸',
				'🇲🇹',
				'🇲🇺',
				'🇲🇻',
				'🇲🇼',
				'🇲🇽',
				'🇲🇾',
				'🇲🇿',
				'🇳🇦',
				'🇳🇨',
				'🇳🇪',
				'🇳🇫',
				'🇳🇬',
				'🇳🇮',
				'🇳🇱',
				'🇳🇴',
				'🇳🇵',
				'🇳🇷',
				'🇳🇺',
				'🇳🇿',
				'🇴🇲',
				'🇵🇦',
				'🇵🇪',
				'🇵🇫',
				'🇵🇬',
				'🇵🇭',
				'🇵🇰',
				'🇵🇱',
				'🇵🇲',
				'🇵🇳',
				'🇵🇷',
				'🇵🇸',
				'🇵🇹',
				'🇵🇼',
				'🇵🇾',
				'🇶🇦',
				'🇷🇪',
				'🇷🇴',
				'🇷🇸',
				'🇷🇺',
				'🇷🇼',
				'🇸🇦',
				'🇸🇧',
				'🇸🇨',
				'🇸🇩',
				'🇸🇪',
				'🇸🇬',
				'🇸🇭',
				'🇸🇮',
				'🇸🇯',
				'🇸🇰',
				'🇸🇱',
				'🇸🇲',
				'🇸🇳',
				'🇸🇴',
				'🇸🇷',
				'🇸🇸',
				'🇸🇹',
				'🇸🇻',
				'🇸🇽',
				'🇸🇾',
				'🇸🇿',
				'🇹🇦',
				'🇹🇨',
				'🇹🇩',
				'🇹🇫',
				'🇹🇬',
				'🇹🇭',
				'🇹🇯',
				'🇹🇰',
				'🇹🇱',
				'🇹🇲',
				'🇹🇳',
				'🇹🇴',
				'🇹🇷',
				'🇹🇹',
				'🇹🇻',
				'🇹🇼',
				'🇹🇿',
				'🇺🇦',
				'🇺🇬',
				'🇺🇲',
				'🇺🇳',
				'🇺🇸',
				'🇺🇾',
				'🇺🇿',
				'🇻🇦',
				'🇻🇨',
				'🇻🇪',
				'🇻🇬',
				'🇻🇮',
				'🇻🇳',
				'🇻🇺',
				'🇼🇫',
				'🇼🇸',
				'🇽🇰',
				'🇾🇪',
				'🇾🇹',
				'🇿🇦',
				'🇿🇲',
				'🇿🇼',
				'🏴󠁧󠁢󠁥󠁮󠁧󠁿',
				'🏴󠁧󠁢󠁳󠁣󠁴󠁿',
				'🏴󠁧󠁢󠁷󠁬󠁳󠁿',
				'🏴󠁵󠁳󠁴󠁸󠁿',
			],
		},
	};

	useEffect(() => {
		if (defaultValue) {
			const blocksFromHtml = htmlToDraft(defaultValue);
			const { contentBlocks, entityMap } = blocksFromHtml;
			const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
			const editorStateTemp = EditorState.createWithContent(contentState);
			setEditorState(editorStateTemp);
		}
	}, []);

	useEffect(() => {
		if (editorState !== '') {
			const rawContentState = convertToRaw(editorState.getCurrentContent());
			onChange(draftToHtml(rawContentState));
		}
	}, [editorState]);

	return (
		<div style={{ minHeight: 350 }}>
			<Editor
				textAlignment="right"
				editorState={editorState}
				toolbarClassName="toolbarClassName"
				wrapperClassName={classes.richTextWraper}
				editorClassName="editorClassName"
				toolbar={toolbar}
				onEditorStateChange={setEditorState}
				stripPastedStyles={true}
			/>
		</div>
	);
};

export default RichTextEditor;
