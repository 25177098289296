import React from 'react';
import { Container, Stack } from '@mui/material';
import Navbar from '../components/Navbar';

const StaticPageContainer = ({ children }) => {
	return (
		<div>
			<Navbar />
			<Container>{children}</Container>
		</div>
	);
};

export default StaticPageContainer;
