import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSigninCheck } from 'reactfire';

const ProtectedRoute = ({ children, ...rest }) => {
	const { data: signInCheckResult } = useSigninCheck();

	return (
		<Route
			{...rest}
			render={({ location }) =>
				signInCheckResult.signedIn ? (
					<Redirect
						to={{
							pathname: '/create',
							state: { from: location },
						}}
					/>
				) : (
					children
				)
			}
		/>
	);
};

export default ProtectedRoute;
