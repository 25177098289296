import React, { useState } from 'react';
import {
	TextField,
	Grid,
	InputAdornment,
} from '@mui/material';
import { makeStyles } from "tss-react/mui";
import { LoadingButton } from '@mui/lab';
import { getFirestore, addDoc, getDocs, query, where, collection } from 'firebase/firestore';
import introBg from "../img/introBg.png";
import mobilePhone from "../img/new phone.png";
import logo2 from "../img/logo2.svg";
import logo from "../img/logo.svg";
import smallPhone from "../img/phonesmall.png";


const Intro = ({id}) => {
	const [isLoading, setIsLoading] = useState(false);

	const db = getFirestore();

	const useStyles = makeStyles()(
    () => ({
				bannerWrap: {
					overflow: 'hidden',
  				position: 'relative',
					height: 'auto',
					display: 'flex',
          justifyContent: 'flex-start',
          marginTop: 100,
          justifyContent: 'center',
          '@media only screen and (max-width: 900px)':{
            height: 'auto',
            display: 'block',
            marginTop: 0
					},
				},
				bannerImg:{
					position: 'absolute',
					left: 0,
					top: 0,
					width: '100%',
					height: '100%',
          '@media only screen and (max-width: 900px)':{
						// height: '70vh'
            display: 'none'
					},
				},
				bannerContent: {
					position: 'relative',
          width: '100%',
					display: 'flex',
					justifyContent: "flex-start",
          flexDirection: 'row-reverse',
          paddingRight: 0,
          paddingTop: 40,
          maxWidth: 1200,
          '@media only screen and (max-width: 900px)':{
						height: 'auto',
            display: 'block',
            paddingBottom: 40,
            paddingLeft: 20,
            paddingRight: 20,
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingTop: 20
					},
          '@media only screen and (max-width: 350px)':{
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 20
					},
				},
				mobilePhone: {
					height: '80%',
          width: '60%',
          position: 'relative',
          '& div':{
            position: 'absolute',
            width: '100%',
            height: '100%',
            maxWidth: 530,
            left: '-50px'
          },
          '@media only screen and (max-width: 900px)':{
            // height: '70vh'
            // display: 'none'
          },
          // left: 0,
          // top: '30px',
				},
        intro: {
          width: '100%',
          '@media only screen and (max-width: 620px)':{
						width: '100%'
					},
        },
        introHead: {
          fontSize: '2.5em',
          fontWeight: 'bold',
          color: '#112f52',
          '@media only screen and (max-width: 900px)':{
            textAlign: 'center'
          }
        },
        description:{
          color: '#000',
          textAlign: 'justify',
          '& p': {
            fontSize: '0.8em'
          },
          '@media only screen and (min-width: 1200px)':{
						fontSize: 19
					},
          '@media only screen and (max-width: 900px)':{
            textAlign: 'center'
          }
        },
        phoneImage: {
          height: '106%',
          position: 'absolute',
          bottom: '-8%',
          left: '23%',
          '@media only screen and (max-width: 900px)':{
						// height: '70vh'
            // display: 'none'
					},
        },
        logo:{
          position: 'absolute'
        },
        
        smallPhone: {
          display: 'none',
          width: '40%',
          height: 'auto',
          '@media only screen and (max-width: 900px)':{
						// height: '70vh'
            display: 'block',
            
					},
          '@media only screen and (max-width: 620px)':{
						// display: 'none'
					},
        }
    })
);

const { classes } = useStyles();

	return (
			<Grid
				container
        id={id}
			>
				<Grid item xs={12}
				className={classes.banner}>
					<div className={classes.bannerWrap}>
            					
						<div className={classes.bannerContent}>
              <div className={classes.intro}>
                <div className={classes.introHead}>
                 من نحن  
                </div>
                <div className={classes.description}>
                  <p>
                   مرحبا جميعاً! نحن موجز، شركة إعلامية هدفها مساعدتكم لتبقوا مطلعين على آخر الأخبار العالمية، والمحلية والاقتصادية فقط في 5 دقائق. 
                  </p>
                  <p>
                    موجز تهدف إلى تقديم تجربة صحفية جديدة تتماشى مع الجيل الجديد، عبر نشرة إلكترونية يومياً في الساعة السادسة صباحاً الى بريدك الإلكتروني.
                    بدأنا برسالة إخبارية يومية واحدة عبر البريد الإلكتروني كي نقوم بتقديم منشورات الأسبوعية الدورية، والتي تركز في الغالب على موضوعات مثل الأعمال والسياسة والتكنولوجيا والرياضة وفن الطهو والثقافة والفن والموضة عبر البريد الإلكتروني.
                  </p>
                  <p>
                    إنطلاقاَ من إيماننا بالنظام الصحفي والإعلامي، الذي على وشك الانقراض في عموم العالم والشرق الأوسط خصوصاً، من هذا النقطة نطرح خدمة إعلامية تهدف إلى تمكين القُرّاء من إدراك العالم من منظور أوسع. نقدم لقرائنا تجربة إخبارية “بسيطة وسهلة” من خلال البريد الإلكتروني ، حيث تصنف الأخبار الواردة من الشرق الأوسط والعالم كل صباح في الساعة 06.00. وبتقديم تجربة جديدة كخدمة إعلامية باستخدام التكنولوجيا، واستخدام تجربة مستخدم سهلة ومستدامة عبر الأنترنت، نقوم بتطوير الخدمات والمحتوى ضمن فريق متكامل من المبرمجين والمحررين والصحفيين 
                  </p>
                  <b>                    
                    ماذا تنتظر!!! تمييز بنهارك… مع حكاية من الأخبار وإنضم الآن.
                  </b>
                </div>

              </div>
						</div>
					</div>
				</Grid>
			</Grid>
	);
};

export default Intro;
