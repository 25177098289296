import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { useSigninCheck } from 'reactfire';
// Style
import { Container } from '@mui/material';
// Components
import LoadingContainer from '../containers/LoadingContainer';
// Pages
import CreateEmail from '../pages/Dashboard/CreateEmail';
import Home from '../pages/Public/Home';
import ErrorPage from '../pages/Public/ErrorPage';
import Newsletters from '../pages/Dashboard/Newsletters';
import PrivateRoute from './PrivateRoute';
import ProtectedRoute from './ProtectedRoute';
import Login from '../pages/Auth/Login';
import About from '../pages/Public/About';
import Contact from '../pages/Public/Contact';
import ManageUsers from '../pages/Dashboard/ManageUsers';
import Landing from '../pages/Public/Landing';
import Unsubscribe from '../pages/Public/Unsubscribe';
import Newsletter from '../pages/Public/Newsletter';

const Router = () => {
	const { status } = useSigninCheck();

	return (
		<LoadingContainer status={status}>
			<BrowserRouter>
				<Switch>
					{/* DASHBOARD ROUTES */}
					<PrivateRoute path="/create">
						<CreateEmail />
					</PrivateRoute>
					<PrivateRoute path="/manage-users">
						<ManageUsers />
					</PrivateRoute>
					{/* END OF DASHBOARD ROUTES */}
					{/* AUTH ROUTES */}
					<ProtectedRoute path="/login">
						<Login />
					</ProtectedRoute>
					{/* END OF AUTH ROUTES */}
					<Route path="/archive">
						<Newsletters />
					</Route>
					<Route path="/about">
						<About />
					</Route>
					<Route path="/contact">
						<Contact />
					</Route>
					<Route path="/newsletters/:id" exact>
						<Newsletter />
					</Route>
					<Route path="/unsubscribe" exact>
						<Unsubscribe />
					</Route>
					<Route path="/:section?/:mb?" exact>
						<Landing />
					</Route>
					{/* <Route path="/" exact>
						<Home />
					</Route> */}
					<Route path="*">
						<ErrorPage />
					</Route>
				</Switch>
			</BrowserRouter>
		</LoadingContainer>
	);
};

export default Router;
