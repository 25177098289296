import { Stack, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ImageUploader from './ImageUploader';

const NewsImageCard = ({ defaultValue, onChange }) => {
	const [section, setSection] = useState(
		defaultValue && defaultValue.section ? defaultValue.section : ''
	);
	const [title, setTitle] = useState(
		defaultValue && defaultValue.title ? defaultValue.title : ''
	);
	const [image, setImage] = useState(
		defaultValue && defaultValue.image ? defaultValue.image : ''
	);
	const [credits, setCredits] = useState(
		defaultValue && defaultValue.credits ? defaultValue.credits : ''
	);
	const [alt, setAlt] = useState(defaultValue && defaultValue.alt ? defaultValue.alt : '');

	useEffect(() => {
		onChange({
			section,
			title,
			image,
			alt,
			credits,
		});
	}, [section, title, image, alt, credits]);

	return (
		<Stack spacing={3} direction="column">
			<Stack direction="column" spacing={1}>
				<TextField
					fullWidth
					value={section}
					onChange={({ target: { value } }) => setSection(value)}
					variant="outlined"
					label="الفقرة"
					placeholder="الفقرة"
				/>
				<TextField
					fullWidth
					value={title}
					onChange={({ target: { value } }) => setTitle(value)}
					variant="outlined"
					label="العنوان"
					placeholder="العنوان"
				/>
			</Stack>
			<ImageUploader image={image} onChange={setImage} />
			<Stack direction="row" spacing={1}>
				<TextField
					fullWidth
					value={credits}
					onChange={({ target: { value } }) => setCredits(value)}
					variant="outlined"
					label="المصدر"
					placeholder="المصدر"
				/>
				<TextField
					fullWidth
					value={alt}
					onChange={({ target: { value } }) => setAlt(value)}
					variant="outlined"
					label="وصف الصورة"
					placeholder="وصف الصورة"
				/>
			</Stack>
		</Stack>
	);
};

export default NewsImageCard;
