import {
	Autocomplete,
	Button,
	Card,
	CardContent,
	Divider,
	Grid,
	IconButton,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useState, useEffect } from 'react';
import { generateKey } from '../utils';

const CurrencyRow = ({ onChange, onDelete, id, item }) => {
	const [currency, setCurrency] = useState(
		item
			? item
			: {
					key: id,
					name: '',
					icon: '',
					value: 0,
			  }
	);

	useEffect(() => {
		onChange(currency);
	}, [currency]);

	return (
		<Stack spacing={1} direction="row" alignItems="center">
			<Autocomplete
				options={[
					'🇪🇺',
					'🇬🇧',
					'🇺🇸',
					'🇹🇷',
					'🇱🇧',
					'🇸🇾',
					'🇮🇶',
					'🇵🇸',
					'🇪🇬',
					'🇸🇩',
					'🇩🇿',
					'🇹🇳',
					'🇲🇦',
					'🇱🇾',
					'🇯🇴',
					'🇮🇷',
					'🇷🇺',
					'🇸🇦',
					'🇰🇼',
					'🇾🇪',
					'🇴🇲',
					'🇧🇭',
					'🇦🇪',
					'🇶🇦',
				]}
				disableClearable
				onChange={(e, icon) => setCurrency({ ...currency, icon })}
				renderInput={(params) => <TextField {...params} label="العلم" />}
			/>
			<TextField
				fullWidth
				label="البلد"
				placeholder="البلد"
				variant="outlined"
				value={currency.name}
				onChange={({ target: { value } }) => setCurrency({ ...currency, name: value })}
			/>
			<TextField
				label="القيمة"
				placeholder="القيمة"
				fullWidth
				variant="outlined"
				value={currency.value}
				onChange={({ target: { value } }) => setCurrency({ ...currency, value: value })}
			/>
			<IconButton onClick={onDelete}>
				<DeleteIcon />
			</IconButton>
		</Stack>
	);
};

const NewsCurrencyCard = ({ onChange, defaultValue }) => {
	const [currencies, setCurrencies] = useState(
		defaultValue
			? defaultValue.currencies
			: [
					{
						key: generateKey(),
						name: '',
						icon: '',
						value: '',
					},
			  ]
	);

	useEffect(() => {
		onChange({ currencies });
	}, [currencies]);

	return (
		<Stack spacing={1}>
			<Typography variant="h5">اسعار الصرف</Typography>
			<Stack direction="column" divider={<Divider />} spacing={2}>
				{currencies.map((city, index) => (
					<CurrencyRow
						key={city.key}
						id={city.key}
						onChange={(newCity) => {
							let temp = [...currencies];
							temp.splice(index, 1, newCity);
							setCurrencies(temp);
						}}
						item={city}
						onDelete={() => {
							let temp = [...currencies];
							temp.splice(index, 1);
							setCurrencies(temp);
						}}
					/>
				))}
			</Stack>
			<Button
				fullWidth
				onClick={() =>
					setCurrencies([
						...currencies,
						{
							key: generateKey(),
							name: '',
							icon: '',
							low: 0,
							high: 0,
						},
					])
				}
			>
				اضافة
			</Button>
		</Stack>
	);
};

export default NewsCurrencyCard;
