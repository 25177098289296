import { Button, Grid, Stack, TextField, Typography } from '@mui/material';
import React from 'react';
import StaticPageContainer from '../../containers/StaticPageContainer';

const Contact = () => {
	return (
		<StaticPageContainer>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Typography>ابقى على تواصل</Typography>
				</Grid>
				<Grid item xs={12} md={6} sm={8}>
					<Stack spacing={3}>
						<TextField label="الاسم" placeholder="الاسم" fullWidth />
						<TextField
							label="البريد الإلكتروني"
							placeholder="البريد الإلكتروني"
							fullWidth
						/>
						<TextField
							label="رسالتك"
							placeholder="رسالتك"
							multiline
							minRows={4}
							fullWidth
						/>
						<Button variant="contained">أرسل رسالتك</Button>
					</Stack>
				</Grid>
			</Grid>
		</StaticPageContainer>
	);
};

export default Contact;
