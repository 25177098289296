import {
	Autocomplete,
	Button,
	Card,
	CardContent,
	Divider,
	Grid,
	IconButton,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useState, useEffect } from 'react';
import { generateKey } from '../utils';
import RichTextEditor from '../atoms/RichTextEditor';

const WeatherRow = ({ onChange, onDelete, id, item }) => {
	const [weather, setWeather] = useState(
		item
			? item
			: {
					key: id,
					name: '',
					icon: '',
					low: 0,
					high: 0,
			  }
	);

	useEffect(() => {
		onChange(weather);
	}, [weather]);

	return (
		<Stack spacing={1} direction="row" alignItems="center">
			<Autocomplete
				options={[
					'☁️',
					'☂️',
					'☔',
					'🌁',
					'❄️',
					'🌫️',
					'🌨️',
					'🌧️',
					'⛈️',
					'🌩️',
					'🌪️',
					'⛅',
					'☀️',
					'🥵',
					'🥶',
					'💨',
				]}
				disableClearable
				onChange={(e, icon) => setWeather({ ...weather, icon })}
				renderInput={(params) => <TextField {...params} label="الرمز التعبيري" />}
			/>
			<TextField
				fullWidth
				label="المدينة"
				placeholder="المدينة"
				variant="outlined"
				value={weather.name}
				onChange={({ target: { value } }) => setWeather({ ...weather, name: value })}
			/>
			<TextField
				label="الحرارة القصوى"
				placeholder="الحرارة القصوى"
				fullWidth
				variant="outlined"
				value={weather.high}
				onChange={({ target: { value } }) => setWeather({ ...weather, high: value })}
			/>
			<TextField
				label="أدنى درجة حرارة"
				placeholder="أدنى درجة حرارة"
				fullWidth
				variant="outlined"
				value={weather.low}
				onChange={({ target: { value } }) => setWeather({ ...weather, low: value })}
			/>
			<IconButton onClick={onDelete}>
				<DeleteIcon />
			</IconButton>
		</Stack>
	);
};

const NewsWeatherCard = ({ onChange, defaultValue }) => {
	const [weather, setWeather] = useState(
		defaultValue
			? defaultValue.weather
			: [
					{
						key: generateKey(),
						name: '',
						icon: '',
						low: 0,
						high: 0,
					},
			  ]
	);

	const [body, setBody] = useState(defaultValue && defaultValue.body ? defaultValue.body : '');

	useEffect(() => {
		onChange({ weather, body });
	}, [weather, body]);

	return (
		<Stack spacing={1}>
			<Typography variant="h5">توقعات الطقس</Typography>
			<Stack direction="column" divider={<Divider />} spacing={2}>
				{weather.map((city, index) => (
					<WeatherRow
						key={city.key}
						id={city.key}
						onChange={(newCity) => {
							let temp = [...weather];
							temp.splice(index, 1, newCity);
							setWeather(temp);
						}}
						item={city}
						onDelete={() => {
							let temp = [...weather];
							temp.splice(index, 1);
							setWeather(temp);
						}}
					/>
				))}
			</Stack>
			<Button
				fullWidth
				onClick={() =>
					setWeather([
						...weather,
						{
							key: generateKey(),
							name: '',
							icon: '',
							low: 0,
							high: 0,
						},
					])
				}
			>
				اضافة
			</Button>
			<RichTextEditor defaultValue={body} onChange={setBody} />
		</Stack>
	);
};

export default NewsWeatherCard;
