import { Grid, Card, CardActions, CardContent, IconButton } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import React, { useCallback } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import NewsCardWithImage from './NewsCardWithImage';
import NewsImageCard from './NewsImageCard';
import NewsStocksCard from './NewsStocksCard';
import NewsWeatherCard from './NewsWeatherCard';
import NewsCurrencyCard from './NewsCurrencyCard';
import {
	generateImageCard,
	generateNewsCard,
	generateStocksCard,
	generateWeatherCard,
	generateCurrencyCard,
} from '../utils';

const generatorsMap = {
	newsCardWithImage: (item) => {
		return generateNewsCard(item);
	},
	newsStocksCard: (item) => {
		return generateStocksCard(item);
	},
	newsImageCard: (item) => {
		return generateImageCard(item);
	},
	newsWeatherCard: (item) => {
		return generateWeatherCard(item);
	},
	newsCurrencyCard: (item) => {
		return generateCurrencyCard(item);
	},
};

const NewsCardContainer = ({ onChange, onDelete, onMove, item, index, total }) => {
	const onChangeItem = (newItem) => {
		onChange(
			{
				...item,
				...newItem,
				html: generatorsMap[item.key](newItem),
			},
			index
		);
	};

	return (
		<Card variant="outlined">
			<CardContent>
				{item.key === 'newsCardWithImage' && (
					<NewsCardWithImage defaultValue={item} onChange={onChangeItem} />
				)}
				{item.key === 'newsImageCard' && (
					<NewsImageCard defaultValue={item} onChange={onChangeItem} />
				)}
				{item.key === 'newsStocksCard' && (
					<NewsStocksCard defaultValue={item} onChange={onChangeItem} />
				)}
				{item.key === 'newsWeatherCard' && (
					<NewsWeatherCard defaultValue={item} onChange={onChangeItem} />
				)}
				{item.key === 'newsCurrencyCard' && (
					<NewsCurrencyCard defaultValue={item} onChange={onChangeItem} />
				)}
			</CardContent>
			<CardActions>
				<Grid container spacing={3} justifyContent="space-between">
					<Grid item>
						<IconButton onClick={() => onDelete(index)}>
							<DeleteIcon />
						</IconButton>
					</Grid>
					<Grid item>
						{Boolean(index < total - 1) && (
							<IconButton onClick={() => onMove('down', index)}>
								<ArrowDownwardIcon />
							</IconButton>
						)}
						{Boolean(index > 0) && (
							<IconButton onClick={() => onMove('up', index)}>
								<ArrowUpwardIcon />
							</IconButton>
						)}
					</Grid>
				</Grid>
			</CardActions>
		</Card>
	);
};

export default NewsCardContainer;
