import React, { useState } from 'react';
import {
  TextField,
  Grid,
  InputAdornment,
  Typography,
  Input,
  TextareaAutosize,
  Button,
} from '@mui/material';
import { makeStyles } from "tss-react/mui";
import { getFirestore, addDoc, getDocs, query, where, collection } from 'firebase/firestore';
import logo from "../img/Mujaz_Logo_Mini.png";
import location from "../img/location.svg";
import smartphone from "../img/smartphone.svg";
import mail from "../img/mail.svg";
import mobilePhone from "../img/phone.png";
import contactBg from "../img/greyBG.png";
import { LoadingButton } from '@mui/lab';


const ContactUs = ({ id }) => {

  const useStyles = makeStyles()(
    () => ({
      wrapper: {
        overflow: 'hidden',
        position: 'relative',
        // height: '90vh',
        display: 'flex',
        width: '100%',
        maxWidth: 1200
      },
      banner: {
        display: 'flex',
        justifyContent: 'center',
      },
      bannerImg: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: 'auto',
      },
      contentWrapper: {
        position: 'relative',
        width: '100%',
        display: 'flex',
        justifyContent: "flex-start",
        paddingLeft: 80,
        paddingTop: 45,
        paddingRight: 80,
        flexWrap: 'nowrap',
        '@media only screen and (max-width: 620px)': {
          flexDirection: 'column-reverse',
          paddingRight: 40,
          paddingLeft: 40
        },
      },
      details: {
        height: '80%',
        width: '30%',
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        alignItems: 'center',
        fontWeight: 600,

        borderRight: '1px solid #112f52',
        paddingRight: 40,
        '@media only screen and (max-width: 620px)': {
          flexDirection: 'row',
          width: '100%',
          borderRight: 'none',
          borderBottom: '1px solid #112f52',
          paddingRight: 0,
          paddingBottom: 15,
          justifyContent: 'space-around'
        },
        '@media only screen and (max-width: 520px)': {
          flexWrap: 'wrap'
        }
      },
      detailInfo: {
        marginTop: 20,
        display: 'flex',
        flexDirection: "row-reverse",
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-evenly',
        display: 'grid',
        gridTemplateColumns: '50% 50%',
        "& img": {
          width: 25,
          justifySelf: 'right',
          marginRight: 40
        },
        '@media only screen and (max-width: 620px)': {
          width: 'auto',
          display: 'flex',
          '& img': {
            width: 15,
            marginRight: 10,
          }
        },
      },
      form: {
        // width: '50%',
        paddingLeft: 60,
        '& input': {
          fontSize: 18,
          borderRadius: 5,
          border: '1px solid #666',
          boxShadow: '0px 1px 3px #ccc',
          background: '#FCFCFC',
          marginTop: 20,
        },
        "& fieldset": {
          border: "none",
          // border: '1px solid '
          outline: 'none'
        },
        '@media only screen and (max-width: 620px)': {
          paddingLeft: 0
        },
      },
      introHead: {
        fontSize: 40,
        fontWeight: 'bold',
        color: '#fff'
      },
      description: {
        color: '#fff',
      },
      phoneImage: {
        width: '100%',
      },
      logo: {
        width: 90,
        marginBottom: 40,
        '@media only screen and (max-width: 620px)': {
          display: 'none',
        },
      },
      detail: {
        color: '#112f52',
        gridColumnStart: 1,
        gridRowStart: 1,
        justifySelf: 'left',
        whiteSpace: 'nowrap',
        '@media only screen and (max-width: 620px)': {
          fontSize: '0.8em',
          textAlign: 'left',
          marginLeft: 5
        },
      },
      connectUs: {
        color: '#112f52',
        fontSize: 30,
        fontWeight: 'bold'
      },
      input: {

        "&::after": {
          content: '"*"',
          position: 'absolute',
          top: '40%',
          right: '-7%',
          fontSize: 30,
          color: '#112f52'
        }
      },
      textArea: {
        borderRadius: 5,
        border: '1px solid #666',
        boxShadow: '0px 1px 3px #ccc',
        background: '#FCFCFC',
        marginTop: 20,
        "&::after": {
          content: '"*"',
          position: 'absolute',
          right: '-7%',
          fontSize: 30,
          color: '#112f52'
        }
      },
      submit: {
        borderRadius: 5,
        width: 120,
        marginTop: 20,
        marginBottom: 10
      },
      error: {
        '& input': {
          border: '1px solid #F79325'
        }
      },
      errorTextArea: {
        border: '1px solid #F79325'
      },
      number: {
        direction: 'ltr'
      }
    })
  );

  const { classes } = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    name: '',
    message: '',
    topic: '',
    email: ''
  });
  const [error, setError] = useState({});

  const db = getFirestore();

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setState({ ...state, [name]: value });

    if (error[name]) {
      error[name] = false
    }

  }

  const validateData = () => {
    let error = {};
    let isValid = true;
    for (let item in state) {
      if (state[item] === null || state[item] === '') {
        error[item] = true
        if (isValid) {
          isValid = false;
        }
      }
    }
    setError({ ...error });
    return isValid;
  }

  const handleSubmit = async () => {

    const isValid = validateData();
    if (isValid) {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        dataType: "json",
        body: JSON.stringify(state)
      };

      try {
        setIsLoading(true);
        await fetch('https://formsubmit.co/ajax/hello@mujaz.io', requestOptions);
        setState({
          name: '',
          message: '',
          topic: '',
          email: ''
        });
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
      }
    }
  }

  return (
    <Grid
      container
      justifyContent={'center'}
      marginTop={15}
      id={id}
    >
      <Typography className={classes.connectUs}>
        تواصل معنا
      </Typography>
      <Grid container
        className={classes.banner}>
        <div className={classes.wrapper}>
          {/* <img
              className={classes.bannerImg}
              src={contactBg}
              alt=""
            />						 */}
          <Grid container className={classes.contentWrapper}>
            <Grid item lg={7} xs={12} className={classes.form}>
              <Grid container justifyContent={"flex-end"} >
                <TextField className={`${classes.input} ${error.name && classes.error}`}
                  onChange={handleChange}
                  fullWidth
                  placeholder='الاسم'
                  name="name"
                  value={state.name}
                />
                <TextField className={`${classes.input} ${error.email && classes.error}`}
                  onChange={handleChange}
                  fullWidth
                  placeholder='البريد الإلكتروني'
                  name="email"
                  value={state.email}
                />
                <TextField className={`${classes.input} ${error.topic && classes.error}`}
                  onChange={handleChange}
                  fullWidth
                  placeholder='الموضوع'
                  name="topic"
                  value={state.topic}
                />
                <TextField onChange={handleChange}
                  fullWidth
                  multiline
                  rows={4}
                  placeholder='الرسالة'
                  name="message"
                  className={`${classes.textArea} ${error.message && classes.errorTextArea}`}
                  value={state.message}
                />
                <LoadingButton
                  type="submit"
                  onClick={handleSubmit}
                  loading={isLoading}
                  className={classes.submit}
                  variant='contained' >
                  إرسال
                </LoadingButton>

              </Grid>
            </Grid>
            <Grid item lg={5} xs={12} className={classes.details}>
              <img src={logo} alt="logo" className={classes.logo} />
              <Grid
                justifyContent={"center"}
                //  flexDirection={'row-reverse'} 
                className={classes.detailInfo}
              >
                {/* <Grid lg={2} xs={2}> */}
                <img src={location} alt="location" />
                {/* </Grid> */}
                {/* <Grid lg={4} xs={10}> */}
                <Typography className={classes.detail}>Istanbul, Turkey</Typography>
                {/* </Grid> */}
              </Grid>
              <Grid
                justifyContent={"center"}
                //  flexDirection={'row-reverse'} 
                className={classes.detailInfo}
              >
                {/* <Grid lg={2} xs={2}> */}
                <img src={smartphone} alt="smartphone" />
                {/* </Grid> */}
                {/* <Grid lg={4} xs={10}> */}
                <Typography className={`${classes.detail} ${classes.number}`}>+90 535 593 8150</Typography>
                {/* </Grid> */}
              </Grid>
              <Grid
                justifyContent={"center"}
                //  flexDirection={'row-reverse'} 
                className={classes.detailInfo}
              >
                {/* <Grid lg={2} xs={2}> */}
                <img src={mail} alt="mail" />
                {/* </Grid> */}
                {/* <Grid lg={4} xs={10}> */}
                <Typography className={classes.detail}>Hello@mujaz.io</Typography>
                {/* </Grid> */}
              </Grid>
              {/* <img src={mobilePhone} alt="phone" /> */}
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};

export default ContactUs;
