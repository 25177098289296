import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { FirebaseAppProvider } from 'reactfire';

// Firebase configuration
// const firebaseConfig = {
// 	apiKey: 'AIzaSyBeJAjSDABAE8i_R6yEAA499XtwF3aL98k',
// 	authDomain: 'mujaz-42d66.firebaseapp.com',
// 	projectId: 'mujaz-42d66',
// 	storageBucket: 'mujaz-42d66.appspot.com',
// 	messagingSenderId: '676864066744',
// 	appId: '1:676864066744:web:d6b4aae3841ba3c061e04d',
// };
const firebaseConfigMujazPlatform = {
		apiKey: "AIzaSyCPRnE1krVgrLxiQwYpya_GN4VMG0F3mrs",
		authDomain: "mujaz-platform.firebaseapp.com",
		projectId: "mujaz-platform",
		storageBucket: "mujaz-platform.appspot.com",
		messagingSenderId: "988604723058",
		appId: "1:988604723058:web:7cd884ca6736903381f7eb",
		measurementId: "G-H9L2H43NBN"
	};
ReactDOM.render(
	<FirebaseAppProvider firebaseConfig={firebaseConfigMujazPlatform}>
		<App />
	</FirebaseAppProvider>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
