import React, { useState, RouterLink } from 'react';
import {
  Grid,
  Typography,
  Button,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActionArea
} from '@mui/material';
import { makeStyles } from "tss-react/mui";
import { LoadingButton } from '@mui/lab';
import { getFirestore } from 'firebase/firestore';
import logo from "../img/logo.svg";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import pic3 from '../img/pic-03.jpg';
import pic4 from '../img/unnamed.jpg';

const Post = ({ date, number, setState, maxPost, title, image }) => {
  const [isLoading, setIsLoading] = useState(false);

  const db = getFirestore();

  const useStyles = makeStyles()(
    () => ({
      container: {
        borderRadius: 5,
        boxShadow: '0px 3px 6px #00000029',
        width: 250,
        marginRight: 5,
        background: '#fff',
        '@media only screen and (max-width: 600px)': {
          justifyContent: 'space-between'
        },
        '@media only screen and (max-width: 415px)': {
          marginRight: 0,
        },
      },
      cardMedia: {
        maxWidth: 'auto',
        width: '100%',
        borderRadius: 0
      },
      cardHeader:{
        '&>div>span': {
          height: 96,
          fontWeight: 500,
          fontSize: 20,
				},
      },
      card: {
        borderRadius: 5,
        marginBottom: 25,
        boxShadow: '1px 1px 5px #ddd',
        maxWidth: 290
      },
      imageHolder: {
        display: 'flex',
        justifyContent: 'center',
        padding: '25px 50px 5px 50px',
        flexDirection: 'column',
        alignItems: 'center',
        color: '#112f52',
      },
      readNow: {
        background: '#888888',
        borderRadius: 50
      },
      smallScreenContainer: {
        '@media only screen and (max-width: 820px)': {
          display: 'none'
        },
      }
    })
  );

  const { classes } = useStyles();

  const history = useHistory();

  function formatDate(dateIn, symbol) {
    let yyyy = dateIn.getFullYear();
    let mm = dateIn.getMonth() + 1;
    if (symbol != '-') {
      mm = mm.toString().padStart(2, '0');
    }
    let dd = dateIn.getDate();
    return String(dd + symbol + mm + symbol + yyyy);
  }

  const handleClick = async () => {
    // let formattedDate = formatDate(date, '-');

    history.push(`/newsletters/${date.replaceAll('.','-')}`);
  }


  return (

    <Card
      className={classes.card}
      sx={{ maxWidth: 345 }}
    >
      <CardActionArea onClick={() => handleClick(date)}>
        <CardHeader
          className={classes.cardHeader}
          title={title}
        />
        <CardMedia
          className={classes.cardMedia}
          component="img"
          height="194"
          image={image}
          alt=""
        />
        <CardContent>
          <Typography
            align="center"
            variant="h6"
          >{date.split('_')[1]}</Typography>
        </CardContent>
      </CardActionArea>

    </Card>

  );
};

export default Post;
