import React, { useEffect, useState } from 'react';
import {
	Grid,
} from '@mui/material';
import { getFirestore, addDoc, getDocs, query, where, collection } from 'firebase/firestore';
import Nav from '../../components/Nav';
import LandingBanner from '../../components/LandingBanner';
// import Intro from '../../components/Intro';
import PreviousPost from '../../components/PreviousPost';
import ContactUs from '../../components/ContactUs';
import Footer from '../../components/Footer';
import { useParams } from 'react-router-dom';

const Landing = () => {
	const [isLoading, setIsLoading] = useState(false);
	const {section,mb} = useParams();

	const db = getFirestore();

	useEffect(() => {
		if(section) {
			scrollPage(section,mb);
		}
	},[section])

	const scrollPage = (id,mobile) => {
		if(id){
			let elmnt = document.getElementById(id);

			let scrollTop = window.pageYOffset || elmnt.scrollTop;	 
			let finalOffset = '';
			if(mobile){
				let mobileNav = document.getElementById('mobile-nav');
				let heightOfNav = parseInt(getComputedStyle(mobileNav).height, 10);
				finalOffset = elmnt.getBoundingClientRect().top + scrollTop - heightOfNav;
			}else{
				finalOffset = elmnt.getBoundingClientRect().top + scrollTop;
			}
	 
		 window.parent.scrollTo({
			 top: finalOffset,
			 behavior: 'smooth'
		});
	}
}

	return (
		<Grid
      container
			sx={{
				  backgroundColor: '#fefefe',
					justifyContent: 'center' }}
      >
			<Nav scrollPage={scrollPage} />
			<LandingBanner id='landing-banner' />
			
			<PreviousPost id="previous-post" />
			<ContactUs id="contact-us" />
			<Footer />
	</Grid>
	);
};

export default Landing;
