import React, { useState } from 'react';
import {
	Avatar,
	Button,
	TextField,
	Link,
	Paper,
	Box,
	Grid,
	Typography,
	Stack,
	Container,
	Alert,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';

const errorMessages = {
	'auth/wrong-password': 'رمز مرور خاطئ',
	'auth/internal-error': 'خطأ داخلي',
	'auth/invalid-user-token': 'رمز المستخدم غير صالح',
	'auth/invalid-verification-code': 'رمز التحقق غير صالح',
	'auth/invalid-email': 'بريد إلكتروني خاطئ',
	'auth/timeout': 'نفذ الوقت',
};

const Login = () => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [errorMessage, setErrorMessage] = useState('');

	const auth = getAuth();

	const onSubmit = () => {
		setErrorMessage('');
		signInWithEmailAndPassword(auth, email, password)
			.then((userCredential) => {
				// Signed in
				const user = userCredential.user;
				// ...
			})
			.catch((error) => {
				console.log(error.code, error.message);
				setErrorMessage(errorMessages[error.code]);
			});
	};

	return (
		<Container maxWidth="sm">
			<Grid container spacing={3}>
				<Grid item xs={12} sx={{ mt: 10 }}>
					<Stack alignItems="center" spacing={3}>
						<Avatar>
							<LockOutlinedIcon />
						</Avatar>
						<Typography component="h1" variant="h5">
							تسجيل الدخول
						</Typography>
						{Boolean(errorMessage) && (
							<Alert variant="standard" color="error" style={{ width: '100%' }}>
								<Typography>{errorMessage}</Typography>
							</Alert>
						)}
						<TextField
							margin="normal"
							fullWidth
							id="email"
							label="البريد الالكتروني"
							autoComplete="email"
							value={email}
							onChange={({ target: { value } }) => setEmail(value)}
						/>
						<TextField
							margin="normal"
							fullWidth
							label="كلمة السر"
							type="password"
							id="password"
							autoComplete="current-password"
							value={password}
							onChange={({ target: { value } }) => setPassword(value)}
						/>
						<Button
							fullWidth
							variant="contained"
							sx={{ mt: 3, mb: 2 }}
							onClick={onSubmit}
						>
							تسجيل الدخول
						</Button>
						<Grid container>
							<Grid item xs>
								<Link href="#" variant="body2">
									نسيت كلمة المرور؟
								</Link>
							</Grid>
						</Grid>
					</Stack>
				</Grid>
			</Grid>
		</Container>
	);
};

export default Login;
