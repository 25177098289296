import React from 'react';
import { Container } from '@mui/material';
import Navbar from '../components/Navbar';

const DashboardContainer = ({ children }) => {
	return (
		<>
			<Navbar />
			<Container>{children}</Container>
		</>
	);
};

export default DashboardContainer;
