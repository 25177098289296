import React, { useState } from 'react';
import {
	Grid,
	Button,
  Menu,
  IconButton,
  MenuItem,
} from '@mui/material';
import { makeStyles } from "tss-react/mui";
import { getFirestore, addDoc, getDocs, query, where, collection } from 'firebase/firestore';
import logo from "../img/logo-01.png";
import new_logo from "../img/Mujaz_Logo.png"
//import hamMenu from "../img/hamMenu.png";
import hamMenu from "../img/menu-icon-navy.png";
import SocialHolder from '../atoms/SocialHolder';
import { useHistory } from 'react-router-dom';

const MobileNav = ({scrollPage}) => {

	const db = getFirestore();

	const useStyles = makeStyles()(
    () => ({
        nav:{
          display: 'none',
          justifyContent: 'space-between',
          flexDirection: 'row-reverse',
          '@media only screen and (max-width: 990px)':{
            background: '#fff',
            display: 'flex',
            padding: '10px 40px',
            position: 'sticky',
            top: 0,
            zIndex: 4
          },
          '@media only screen and (max-width: 350px)':{
            display: 'flex',
            padding: '10px 15px'
          },
        },
				menuButton : {
					color: '#000000',
					fontSize: '0.9em',
				},
				menuButtonHolder:{
					display: 'flex',
				},
				logo:{
					width: 125,
          '@media only screen and (max-width: 450px)':{
           //width: '100%'
          // width: '35%'
          },
				},
        socialHolder:{
          '& >.icon':{
              marginRight: 11
          },
        },
				socials: {
          '&::before': {
            borderTop: '1px solid #112f52',
            width: 195,
            position: 'absolute',
            left: 20,
            content: '""',
          }
        },
        menu: {
          '.MuiPaper-root' : {   
            borderRadius: 25,
            width: 235
          }
        }
    })
);

const { classes } = useStyles();

const [anchorEl, setAnchorEl] = React.useState(null);
const history = useHistory();
const open = Boolean(anchorEl);
const handleClick = (event) => {
  setAnchorEl(event.currentTarget);
};
const handleClose = (id) => {
  setAnchorEl(null);
  if(typeof id === 'string'){
    scrollPage(id,true);
  }
};

	return (
			<Grid
				container
				alignItems={"center"}
				height="fit-content"
        className={classes.nav}
        id="mobile-nav"
				>
				<Grid item>
						<img className={classes.logo} src={new_logo} alt="logo" onClick={() => history.push('/')} />
				</Grid>
				<Grid item xs={2} className={classes.menuButtonHolder}>
          <IconButton id="ham-menu" aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick} >
          <img src={hamMenu} alt="menu" />
          </IconButton>
          <Menu
          id="ham-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          className={classes.menu}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem width={235} onClick={() => handleClose('landing-banner')}>
								<Button className={classes.menuButton} variant="text">الرئيسية</Button>			
          </MenuItem>
          <MenuItem onClick={() => handleClose('intro')}>
                <Button className={classes.menuButton} variant='text'>	من نحن	</Button>
          </MenuItem>
          <MenuItem onClick={() => handleClose('previous-post')}>
                <Button className={classes.menuButton} variant='text'>
										النشرات السابقة
								</Button>
          </MenuItem>
          <MenuItem onClick={() => handleClose('contact-us')}>
            <Button className={classes.menuButton} variant="text">
								التواصل معنا
							</Button>
          </MenuItem>
          <MenuItem onClick={handleClose} >
            <div className={classes.socials}>
              <SocialHolder className={classes.socialHolder} />
            </div>
          </MenuItem>
        </Menu>
				</Grid>
			</Grid>
	);
};

export default MobileNav;
