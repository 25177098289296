import React, { useState, useEffect, Fragment } from 'react';
import {
	Button,
	Container,
	Divider,
	Grid,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Paper,
	Typography,
} from '@mui/material';
import { collection, getDocs, getFirestore } from 'firebase/firestore';
import { Link as RouterLink } from 'react-router-dom';

const Newsletters = () => {
	const db = getFirestore();
	const [docs, setDocs] = useState([]);

	const getDocuments = async () => {
		const querySnapshot = await getDocs(collection(db, 'newsletters'));
		let temp = [];
		querySnapshot.forEach((doc) => {
			temp.push({ id: doc.id, ...doc.data() });
		});
		setDocs(temp);
	};

	useEffect(() => {
		if (!docs.length) {
			getDocuments();
		}
	}, []);

	return (
		<Container>
			<Grid container spacing={3} justifyContent="center">
				<Grid item xs={12}>
					<Typography variant="h3">لائحة نشرات البريد</Typography>
				</Grid>
				<Grid item xs={12}>
					<Paper elevation={0} variant="outlined">
						<List disablePadding>
							{docs.map((item) => (
								<Fragment key={item.id}>
									<ListItem
										disablePadding
										component={RouterLink}
										to={`/newsletters/${item.id}`}
										color="inherit"
									>
										<ListItemButton>
											<ListItemText
												color="inherit"
												primary={item.subject}
												secondary={item.id}
											/>
										</ListItemButton>
									</ListItem>
									<Divider variant="fullWidth" />
								</Fragment>
							))}
						</List>
					</Paper>
				</Grid>
				<Grid item>
					<Button variant="outlined">تحميل الميد</Button>
				</Grid>
			</Grid>
		</Container>
	);
};

export default Newsletters;
